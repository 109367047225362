/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Component } from "react";
import firebase from "firebase/compat/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { getDatabase, ref, set, onValue, get, child } from "firebase/database";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
  };

  async login() {
    const { email, password } = this.state;

    // try {
    //   await firebase
    //     .auth()
    //     .signInWithEmailAndPassword(email, password)
    //     .then(() => {
    //       this.setState({
    //         email: "",
    //         password: "",
    //         loading: false,
    //       });
    //     });
    // } catch (error) {
    //   console.log(error.toString());
    // }
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  async signup() {
    const { email, password } = this.state;
    const auth = getAuth();
    const db = getDatabase();

    try {
      await createUserWithEmailAndPassword(auth, email, password).then(
        (userCredential) => {
          var user = userCredential.user;
          var DSName = user.displayName ? user.displayName : user.email.split("@")[0];

          set(
            ref(db, "/users/profiles/" + user.uid),
            {
              name: DSName,
              email: email,
              uid: user.uid,
            }
          ).catch(function (error) {
            console.log(error);
          });

          this.setState({
            email: "",
            password: "",
            loading: false,
          });
        }
      );
    } catch (error) {
      console.log(error.toString());
    }

    updateProfile(auth.currentUser, {
      displayName: email.split("@")[0]
    }).then(() => {
      // console.log(displayName)
      console.log('hello')
    })

    //     const auth = getAuth();
    // createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in
    //     const user = userCredential.user;
    //     // ...
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // ..
    //   });
  }

  renderButton() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <button style={styles.buttonStyle} onClick={this.login.bind(this)}>
          Log In
        </button>
        <button style={styles.buttonStyle} onClick={this.signup.bind(this)}>
          Sign Up
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        E-mail:
        <input
          type="text"
          name="email"
          placeholder="Enter your e-mail"
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          style={styles.inputStyle}
        />
        Password:
        <input
          type="password"
          name="pass"
          placeholder="Enter your password"
          value={this.state.password}
          onChange={(e) => this.setState({ password: e.target.value })}
          style={styles.inputStyle}
        />
        <div>{this.renderButton()}</div>
      </div>
    );
  }
}

const styles = {
  inputStyle: {
    width: "150px",
    margin: "5px",
  },
  buttonStyle: {
    display: "inline-block",
    margin: "5px",
    padding: "5px",
    cursor: "pointer",
  },
};

export default LoginForm;
