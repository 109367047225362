/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import firebase from './Firebase';
import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";
import { getDatabase, ref, set, onValue } from "firebase/database";

class ProfilePage extends Component {
  state = {
    name: "",
    email: ""
  }

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile() {
    const auth = getAuth();
    var user = auth.currentUser;
    var name, email;

    if (user != null) {
      name = user.displayName;
      email = user.email;
      // console.log(email)
      // console.log('456')
    }
    // console.log(user)
    // console.log('123')
    // console.log(name);


    this.setState({name, email});
  }

  updateProfiles() {
    const auth = getAuth();
    const db = getDatabase();
    var user = auth.currentUser;
    var { name, email } = this.state;
    // console.log(user)
    // console.log(name)
    // updateProfile({
    //   displayName: name,
    //   email: email
    // }).then(() => {
    //   console.log("Profile Updated");
    // });

    set(ref(db,"/users/profiles/" + user.uid), {
      name:name,
      email:email,
      uid: user.uid
    })

    // ref(db, '/users/profiles/' + user.uid).update({ name })
    //   .then(() => console.log('added user profile'))
    //   .catch((err) => console.log('error in user profile node'));
  }

  render() {

    return (
      <div>
        <h2>Update Profile</h2>
        <form>
          <label style={{display: 'block', marginBottom: '5px', marginLeft: '5px'}}>
            E-mail:
          <input
            style={{marginLeft: '5px', textAlign:'center'}}
            type="text"
            name="email"
            value={this.state.email}
            onChange={(e) => {this.setState({email: e.target.value})}}
          />
          </label>
          <label style={{display: 'block', marginBottom: '5px'}}>
            Display Name:

            <input
              style={{marginLeft: '5px', textAlign:'center'}}
              type="text"
              name="name"
              value={this.state.name}
              onChange={(e) => {this.setState({name: e.target.value})}}
            />
            </label>
            <button
              style={styles.buttonStyle}
              onClick={this.updateProfiles.bind(this)}
              value="Update Profile"
            >
              Update Profile
            </button>

        </form>
      </div>
    )
  }
}

const styles =   {
  buttonStyle: {
    display: 'inline-block',
    margin: '5px',
    padding: '5px',
    cursor: 'pointer'
  }
}
export default ProfilePage;
