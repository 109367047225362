/* eslint-disable import/no-anonymous-default-export */
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore"
import firebase from "firebase/compat/app"
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBE9w79Udq0EFhSokpqOqfZdS38JJCiN48",
    authDomain: "nflteams-d7810.firebaseapp.com",
    databaseURL: "https://nflteams-d7810-default-rtdb.firebaseio.com/",
    projectId: "nflteams-d7810",
    storageBucket: "nflteams-d7810.appspot.com",
    messagingSenderId: "296991738969",
    appId: "1:296991738969:web:05aceac271b82d84c77548",
    measurementId: "G-692ZKNJ3JJ"
  };

// //   firebase.initializeApp(firebaseConfig)
// const app = firebase.initializeApp(firebaseConfig);

// // Get a reference to the database service
// const database = getDatabase(app);

// console.log(database)
firebase.initializeApp(firebaseConfig)
const storage = getStorage();

export default {firebase, storage}