/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { getDatabase, ref, set, onValue, get, child } from "firebase/database";
import firebase from "./Firebase";
import "../NFL/ResultsTable.css";
import {Table} from 'react-bootstrap'

/* Component used for rendering weekly results, when the user
 * clicks on the results tab. Results are displayed as a table,
 * with the users in the rows, and the games in the columns.
 * 0 will be displayed for games that were picked incorrectly,
 * whereas the value of the pick will be displayed for games
 * picked correctly. Games missing a pick are given a '-' in order
 * to differentiate them from wrong picks.
 */
class ResultsPage extends Component {
  state = {
    results: {},
    users: {},
    totals: {},
  };

  componentWillMount() {
    this.fetchResults(this.props.week);
    // this.getWeeklyGames(this.props.week)
  }

  componentWillReceiveProps(newProps) {
    if (this.props.week !== newProps.week) {
      this.fetchResults(newProps.week);
      // this.getWeeklyGames(this.props.week)
    }
  }

  fetchResults(week) {
    const dbRef = ref(getDatabase());

    // var resultsRef = ref(db, '/results/2021/' + week + '/users/');
    get(child(dbRef, "/results/2021/" + week + "/users/")).then(
      (snapshot) => {
        var results = {};
        snapshot.forEach((result) => {
          results[result.key] = result.exportVal();
        });
        this.setState({ results }, () => {
          var promise = this.getUserNames();
          Promise.resolve(promise).then((users) => {
            this.setState({ users });
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );


    get(child(dbRef, "/results/2021/total/users")).then(
      (snapshot) => {
        var totals = {};
        snapshot.forEach((total) => {
          totals[total.key] = total.exportVal();
        });
        this.setState({totals}, () => {
          var promise = this.getUserNames()
          Promise.resolve(promise).then((users) => {
            this.setState({ users });
          })
        })
      }
      
      )

  }

  async getUserNames() {
    try {
      const dbRef = ref(getDatabase());
      var user = {};
      await get(child(dbRef, "/users/profiles")).then((snapshot) => {
        user = snapshot.exportVal();
        // console.log(user)
      });
      return user;
    } catch (error) {
      console.log(error);
    }
  }

  generateUserRows() {
    //used by the renderResults function to generate the user rows in
    //the final results table
    var usersResults = Object.assign({}, this.state.results);
    var userTotals = Object.assign({}, this.state.totals);
    // console.log(userTotals)
    // console.log(usersResults)

    const users = Object.assign({}, this.state.users);
    const games = this.props.games;
    var htmlResults = [];
    for(let i = 0; i < 1; i++){
      Object.keys(users).forEach((user) => {
        // For each user in the users object, generate a row for the table
        var userRow = [];
        var totalScore = 0;
        userRow.push(<td key={user}>{users[user].name}</td>);
  
        Object.keys(games).forEach((gameId) => {
          console.log(users[user])
          // console.log(usersResults[user][gameId])
          var result = "";
          var WinnerAB = "";
          //Nested && statement to make sure the given keys/props exist for the
          //returned object. This is necessary in case a user doesn't have results
          //in the usersResults object, or doesn't have a particular gameId in their
          //results for the week (because they missed a pick)
          if (usersResults && usersResults[user] && usersResults[user][gameId]) {
            result = usersResults[user][gameId].result;
            WinnerAB = usersResults[user][gameId].WinnerAB;
            // console.log(usersResults[user][gameId].WinnerAB)
            // console.log(userTotals[user])
          } else result = "--";
  
          if (result !== "--") {
            totalScore += parseInt(result, 10);
          }
          userRow.push(
            <td
              key={gameId + "-" + users[user]}
              style={
                result !== "--"
                  ? result === 1
                    ? styles.pickedStyle
                    : styles.notPickedStyle
                  : styles.defaultStyle
              }
            >
              {WinnerAB}
            </td>
          );
        });
        userRow.push(<td key={users[user].uid}>{totalScore}</td>);
        // userRow.push(<td key={users[user].email}>{userTotals[user].wins} - {userTotals[user].losses}</td>);
        // userRow.push(<td key={users[user].name}>{userTotals[user].percentage}</td>);
        htmlResults.push(userRow);
      });
    }


    return htmlResults;
  }

  renderResults() {
    const games = this.props.games;
    // console.log(games)

    //tableHeader is the html elements for the column headers
    var tableHeader = [<th key={"topLeft"}> </th>];
    Object.keys(games).forEach((gameId) => {
      tableHeader.push(
        <th key={gameId}>
          {games[gameId].homeTeam} vs. {games[gameId].awayTeam}
        </th>
      );
    });
    tableHeader.push(<th key={"Total"}>Total</th>);
    // tableHeader.push(<th key={"wins"}>Wins-Losses</th>);
    // tableHeader.push(<th key={"win%"}>Win Percentage</th>);
    return (
      <div className="table-responsive-sm">
      <Table className="results" cellPadding="7" responsive="sm">
        <thead>
          <tr>{tableHeader}</tr>
        </thead>
        <tbody>
          {this.generateUserRows().map((row, id) => (
            <tr key={id}>{row}</tr>
          ))}
        </tbody>
      </Table>
      </div>

    );
  }

  render() {
    return (
      <div style={{ display: "inline-block" }}>
        {"Week " + this.props.week}
        {this.renderResults()}
      </div>
    );
  }
}

const styles = {
  pickedStyle: {
    backgroundColor: "#7fc17f",
    // height: '60px'
  },
  notPickedStyle: {
    backgroundColor: "#ff9999",
    // height: '60px'
  },
  defaultStyle: {
    backgroundcolor: "none",
    // height: '60px'
  },
};

export default ResultsPage;
