/* eslint-disable no-unused-vars */
import React, { useEffect, useState,Component } from 'react';
import momentjs from 'moment';
import Moment from 'react-moment';
import 'moment-timezone';
import { getStorage,ref, getMetadata, child, getDownloadURL, listAll} from "firebase/storage";
import { getDatabase, set, onValue,push} from "firebase/database";
import {InputGroup} from 'react-bootstrap'


class GameCard extends Component {
  state = {
    hasGuess: false,
    gameIsOver: null,
    checked: false,
    image: '',
    Home: [],
    Away: []
  }

  componentWillMount() {
    if (this.checkGameHasStarted(this.props.startTime, this.props.date))
      this.setState({gameIsOver: true});
  }

  onValueChange(e) {
    if (this.checkGameHasStarted(this.props.startTime, this.props.date)) {
      this.setState({gameIsOver: true});
      return;
    }
    const { target } = e;
    const key = target.getAttribute('name');
    const abbr = target.getAttribute('id');

    const prevValue = this.props.gamePick.guessValue;
    const value = e.target.value;
    // console.log(prevValue)
    // console.log(value)


    if (value === "") {
      this.props.changeNumberUsed(null, prevValue);
      this.setState({hasGuess:false});
      this.props.changePickObject(this.props.gameId, {teamGuess: null, guessValue: null, teamAbbr: null});
    } else {
      this.props.changeNumberUsed(value, prevValue);
      this.setState({hasGuess: true});
      this.props.changePickObject(this.props.gameId, {teamGuess: key, guessValue: value, teamAbbr: abbr});
    }
  }

  formatGameTimeHeader(time, date) {
    //gameTime format is two seperate strings, which have been scraped from nfl.com
    //this is required to convert them to a proper datetime object using moment,
    //and format it nicely for the user
    const dateToFormat = date + ' ' + time;
    const momentObj = (momentjs.tz(dateToFormat, "YYYYMMDD hh:mmAA", "America/New_York"));
    const momentDate = <Moment format="ddd MMM D">{momentObj}</Moment>
    const momentTime = (time==='FINAL') ? 'FINAL' : <Moment format="h:mm A" tz="America/New_York">{momentObj}</Moment>
    return (<tr>
              <th>{momentDate}</th>
              <th>{momentTime}</th>
              <th>{this.checkGameHasStarted(time, date) && time !=='FINAL' ? "FINAL" : ""}</th>
            </tr>)
  }

  checkGameHasStarted(time, date) {
    //checks whether the game has started or not
    const dateToFormat = date + ' ' + time;
    if (momentjs.tz(dateToFormat, "YYYYMMDD hh:mmAA", "America/New_York") <= momentjs())
      return true;
    return false;
  }

  isDisabled(teamGuess, teamChar, startTime, date) {
    /* The input field will be disabled for two reasons:
     * 1. The game has started, and so the user can no longer enter picks (this is checked during the
          input fields on change function, and during rendered)
     * 2. The game already has a pick for the other team. This is to protect the user from picking both teams,
     *    by accident or on purpose.
     */
    return ((this.state.hasGuess && teamGuess === teamChar) || this.checkGameHasStarted(startTime, date)) ? "disabled" : "";
  }

  // getImage (image) {
  //   const storage = getStorage()
  //   console.log(storage)
  //   let { state } = this
  //   storage.child(`${image}.gif`).getDownloadURL().then((url) => {
  //     state[image] = url
  //     this.setState(state)
  //   }).catch((error) => {
  //     // Handle any errors
  //   })
  // }

  NFLAway() {
    const { homeTeam, awayTeam, date, startTime } = this.props;

    const storage = getStorage();
    const listRef = ref(storage, 'Teams/Away');
    listAll(listRef).then((res) => {
      res.items.forEach((itemRef) => {
        let test = itemRef._location.path_
        const test2 = test.split("/")
        const test3 = test2[2].split(".gif")
        if(homeTeam.includes(test3[0])){
          this.setState({Home:itemRef._location.path_})
        } else if (awayTeam.includes(test3[0])){
          this.setState({Away:itemRef._location.path_})
        }
      });
    })
  }

  GetURL() {
      const storage = getStorage();
      const test5 = this.state.Away
      getDownloadURL(ref(storage, 'Teams')).then((url) => {

      // `url` is the download URL for 'images/stars.jpg'
      // This can be downloaded directly:

      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
      };
      xhr.open('GET', url);
      xhr.send();
      this.setState({image: url})
      // Or inserted into an <img> element
      // const img = document.getElementById('myimg');
      // if(img !== null){
      //   img.setAttribute('src', url);
      // }
      }) 
  }


  TestingClass(){
    const storage = getStorage();
    const listRef = ref(storage, 'Teams');
    const db = getDatabase();


    listAll(listRef).then((res) => {
      // for(let i = 0; i < res.items.length; i++){
      //     console.log(res.items[i].name)
      // }
      console.log(res.items)
    })
  }



  render() {
    const { teamGuess, guessValue } = this.props.gamePick;
    const { homeTeam, awayTeam, date, startTime, HTAbbr, ATAbbr, ALogo, HLogo } = this.props;
    // console.log(this.TestingClass())
    // console.log(awayTeam)
    // this.TestingClass()

    /* There are a handful of style changes related to the users selection of
     * picks / teams. When a selection is made for one team (for example the
     * home team), the input field for the opposite team will be disabled (for
     * example the away team), and a background color will be added to the two
     * teams to represent your seleciton (Green for the team they expect to win;
     * red for the team they expect to lose).
     */
    return (
      <table style={styles.cardStyle}>
        <thead>
          {this.formatGameTimeHeader(startTime, date)}
        </thead>
        <tbody>
          <tr style={(teamGuess !== null) ? ((teamGuess === 'A') ? styles.pickedStyle : styles.notPickedStyle) : styles.defaultStyle}>
            <td>
              <img
                style={{verticalAlign: 'middle', height: '50px'}}
                // src={`http://i.nflcdn.com/static/site/7.5/img/logos/teams-matte-80x53/${awayTeam}.png`}
                src={ALogo}
                alt="away-team-img"
              />
            </td>
            <td>{awayTeam}</td>
            <td>
              {/* <input
                name="A"
                type="text"
                value={(teamGuess === 'A') ? guessValue : ""}
                style={{width:"50px"}}
                disabled={this.isDisabled(teamGuess, 'H', startTime, date)}
                onChange={(e) => this.onValueChange(e) }
              /> */}
              <InputGroup>
              <InputGroup.Checkbox
              name="A"
              id={ATAbbr}
              checked={this.state.checked}
              aria-label="Checkbox for following text input"
              value={this.state.checked ? '' : '1'}
              disabled={this.isDisabled(teamGuess, 'H', startTime, date)}
              onChange={(e) => this.onValueChange(e) } 
              onClick={(e) => this.setState({checked:e.currentTarget.checked})}
              />
              </InputGroup>
            </td>
          </tr>
          <tr style={(teamGuess !== null) ? ((teamGuess === 'H') ? styles.pickedStyle : styles.notPickedStyle) : styles.defaultStyle}>
            <td>
              <img
                style={{verticalAlign: 'middle', height: '50px'}}
                // src={`http://i.nflcdn.com/static/site/7.5/img/logos/teams-matte-80x53/${homeTeam}.png`}
                // src={`https://www.thesportsdb.com/api/v1/json/1/searchteams.php?t=Denver-Broncos`}
                // src={this.state.image}
                src={HLogo}
                alt="home-team-img"
              />
            </td>
            <td>{homeTeam}</td>
            <td>
              {/* <input
                name="H"
                type="text"
                value={(teamGuess === 'H') ? guessValue : ""}
                style={{width:"50px"}}
                disabled={((this.state.hasGuess && teamGuess === 'A') || this.checkGameHasStarted(startTime, date)) ? "disabled" : ""}
                onChange={(e) => this.onValueChange(e) }
              /> */}
              <InputGroup>
              <InputGroup.Checkbox
                name="H"
                id={HTAbbr}
                aria-label="Checkbox for following text input"
                value={this.state.checked ? '' : '1'}
                disabled={((this.state.hasGuess && teamGuess === 'A') || this.checkGameHasStarted(startTime, date)) ? "disabled" : ""} 
                onChange={(e) => this.onValueChange(e) } 
                onClick={(e) => this.setState({checked:e.currentTarget.checked})}
              />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

GameCard.defaultProps = {
  gamePick: {
    teamGuess: null,
    guessValue: null,
    teamAbbr: null
  }
};

const styles = {
  cardStyle: {
    display:"inline-block",
    borderStyle:"solid",
    padding: '2px',
    margin: '5px',
    borderCollapse: 'collapse'
  },
  pickedStyle: {
    backgroundColor: '#7fc17f',
    height: '60px'
  },
  notPickedStyle: {
    backgroundColor: "#ff9999",
    height: '60px'
  },
  defaultStyle: {
    backgroundcolor: "none",
    height: '60px'
  }
}

export default GameCard;
