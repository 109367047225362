/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ProfilePage from './NFL/ProfilePage';
import PicksPage from './NFL/PicksPage';
import ResultsPage from './NFL/ResultsPage';
import WeeksDropDown from './NFL/WeeksDropDown';
import LoginForm from './NFL/LoginForm'
import firebase from './NFL/Firebase';
import TotalsPage from './NFL/TotalsPage';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, onValue, get, child} from "firebase/database";

import './App.css';
import 'react-dropdown/style.css';
import 'react-tabs/style/react-tabs.css';


class App extends Component {
  state = {
    games: {},
    selectedWeek: {value:"17", label: "17"},
    currentUser: null,
    loading: false
  }


  componentWillMount() {
    this.fetchWeek(this.state.selectedWeek.value);
    // Firebase listener to check for a log-in/log-out by the user

    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     var currentUser = user;

    //     this.setState({currentUser});
    //     console.log("User logged in.");
    //   } else {
    //     this.setState({ currentUser: null });
    //   }
    // });
    const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    var currentUser = user;

    this.setState({currentUser});
    // console.log("User logged in.");
    const uid = user.uid;
    // ...
  } else {
    // User is signed out
    // ...
    this.setState({ currentUser: null });
  }
});
  }

  fetchWeek(week) {
    /*  App keeps track of the selected week and fetches the games
     *  associated with that week on week change. These games are used
     *  by the picks page and the results page for rendering.
     *  Weeks can be changed by user via the week-dropdown menu.
     */
    // const db = getDatabase();
    //  var results = ref(db, '/games/2021/allGames/' + week).once('value')
    //   .then((snap) => {
    //     const games = snap.exportVal();
    //     this.setState({ games });
    //   });

    const dbRef = ref(getDatabase());
    get(child(dbRef, '/games/2021/allGames/' + week)).then((snapshot) => {
      if(snapshot.exists()) {
        const games = snapshot.exportVal();
        this.setState({ games });
      } else {
        console.log('no data')
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  onWeekSelect (week) {
    /* Used by the week drop down menu to change the selected
     * week in the state, and update the games via fetchWeek
     */
    if (week.value !== this.state.selectedWeek.value) {
      this.setState({selectedWeek: week},
        () => {
          this.fetchWeek(week.value);
        });
    }
  }

  async logout() {
    const auth = getAuth();
    try {
      await auth.signOut()
        .then(() => {
          console.log("logged Out!");
        });
    } catch (error) {
      console.log('log out error');
      console.log(error);
    }
  }

  renderLogin() {
    if (this.state.currentUser != null) {
      return <button style={styles.buttonStyle} onClick={() => this.logout()}>Logout</button>
    }
    else {
      return <LoginForm />
    }
  }

  renderMain() {

    // console.log(this.state.selectedWeek)
    // console.log(this.state.currentUser)
    // console.log(this.state.games)
    if (this.state.currentUser != null) {
      return (
        <Tabs>
          <TabList>
            <Tab>Picks</Tab>
            <Tab>Results</Tab>
            <Tab>Totals</Tab>
            <Tab>Profile</Tab>
          </TabList>
          <WeeksDropDown
            onWeekSelect={this.onWeekSelect.bind(this)}
            selectedWeek={this.state.selectedWeek}
            games={this.state.games}
          />
          <TabPanel>
            <PicksPage
              week={this.state.selectedWeek.value}
              currentUser={this.state.currentUser}
              games={this.state.games}
            />
          </TabPanel>
          <TabPanel>
            <ResultsPage
              week={this.state.selectedWeek.value}
              currentUser={this.state.currentUser}
              games={this.state.games} />
          </TabPanel>
          <TabPanel>
            <TotalsPage
              week={this.state.selectedWeek.value}
              currentUser={this.state.currentUser}
              games={this.state.games} />
          </TabPanel>
          <TabPanel>
            <ProfilePage />
          </TabPanel>
        </Tabs>
      )
    }
  }

  render() {

    return (
      <div className="App">
        <div style={styles.stickyStyle}></div>
        <div style={styles.mainContent}>
          {this.renderLogin()}
          {this.renderMain()}
        </div>
      </div>
    );
  }
}

const styles = {
  mainContent: {
    // marginRight: '40px'
    paddingBottom: '351px',
    backgroundColor: 'dimgrey',
    position: 'absolute'
  },
  buttonStyle: {
    display: 'inline-block',
    margin: '5px',
    padding: '5px',
    cursor: 'pointer'
  },
}

export default App;
